@media (min-width:669px) {
    .main-div {
        width: 600px;
    }
    .logo img {
        width: 128px;
    }
    .menu {
        font-size: 28px;
    }
    .lafz-card {
        padding: 1rem;
    }
    .lafz h1 {
        font-size: 20px;
        color: #EEEEEE;
        padding-right: 0.2rem;
    }
    .lafz h4 {
        font-size: 16px;
        color: #eeeeee69;
        padding-right: 0.4rem;
    }
    .lafz-description p {
        font-size: 15px;
        color: #EEEEEE;
    }
    .lafz i {
        font-size: 14px;
        color: #eeeeee69;
    }
    .lafz-hashtag a {
        font-size: 14px;
    }
    .lafz-icon i {
        font-size: 17px;
    }
    .back {
        padding: 0.16rem;
        font-size: 28px;
        color: #FFD369;
    }
    .dashboard-panel {
        font-size: 0.9em;
    }
    .dashboard-bar {
        width: 17%;
    }
    .main-panel {
        width: 83%;
    }
    .lafz-input {
        width: 400px;
        height: 50px;
    }
    #input-description {
        height: 150px !important;
    }
    .save-btn {
        font-weight: bolder;
    }
    .login-btn {
        width: 280px;
        height: 240px;
    }
    .login-btn h1 {
        font-size: 2.4em;
    }
    .comment-btn button {
        width: 50%;
    }
}

@media (min-width:1500px) {
    .main-div {
        width: 800px;
    }
    .dashboard-panel {
        font-size: 1em;
    }
}