@font-face {
    font-family: "Raleway";
    src: url(../fonts/Raleway-ExtraBoldItalic.woff) format('woff');
}

@font-face {
    font-family: "Yekan";
    src: url(../fonts/iranyekanwebmedium.woff) format('woff');
}

@font-face {
    font-family: "YekanHeavy";
    src: url(../fonts/YekanBakhHeavy.woff) format('woff');
}

